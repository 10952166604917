<template>
  <div class="side-bar">
    <div class="--fixed">
      <div class="__top" v-on:click="$emit('nav-to', '/')">
        <img
          src="../assets/images/max-jaksa-title.svg"
          alt="Max Jaksa really likes pizza. This is his website."
        />
        <lottie
          v-bind:animation-data="require('../assets/animations/data.json')"
          v-bind:animation-speed="1.5"
          v-bind:autoplay="true"
          v-bind:loop="true"
          class="pizza-anim"
        />
      </div>
      <div class="__bottom">
        <b class="--body-sm">(he / him / his)</b>
        <a class="--body-sm" href="mailto:max.jaksa@gmail.com"
          >max.jaksa@gmail.com</a
        >
        <a
          class="--body-sm"
          href="https://www.linkedin.com/in/max-jaksa-76b2755/"
          >LinkedIn</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnim from "../components/LottieAnim.vue";

export default {
  name: "SideBar",
  emits: ["nav-to"],
  components: {
    lottie: LottieAnim,
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  position: relative;
  height: 100%;
  width: 100%;
  .--fixed {
    position: fixed;
    width: 30%;
    max-width: 450px;
    height: 90%;
    .__top {
      padding: $padding-lg;
      position: relative;
      height: calc(100% - 100px);
      cursor: pointer;
    }
    .__bottom {
      display: flex;
      flex-direction: column;
      padding: 0 $padding-lg;
    }
  }
}

.pizza-anim {
  width: 47%;
  margin-top: -5px;
  margin-left: -15px;
}
</style>
