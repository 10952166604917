import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import storageHelper from "storage-helper";

import './styles/main.scss'

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        const password = storageHelper.getItem('user-password');
        if (password === null || password === '') {
            // MJaksa: for adding password protection.
            // next('/protected');
            next();
        }
        else {
            next();
        }
    } else {
        next();
    }
})

createApp(App).use(router).mount('#app')