<template>
  <div>
    <h4>About Me</h4>
    <div class="__columns">
      <div class="__row">
        <div class="portrait" />
        <div class="__column">
          <p class="--paragraph-condensed" style="margin-top: 0">
            I like to make games — <b>a lot.</b>
          </p>
          <p class="--paragraph-condensed">
            More specifically, I'm deeply interested in exploring the boundaries
            between work & play and understanding how the two flow together. I
            spend my days working with a cross-disciplinary team of scientists,
            designers, and engineers developing game-based experiences designed
            to teach, reinforce, and measure.
          </p>
        </div>
      </div>
      <div>
        <p class="--paragraph-condensed">
          I live with my family on Chicago’s north side. I'm a bad (<i
            >yet determined</i
          >) chess player. I like pizza, hotdogs, washing the dishes, and
          Detroit Basketball.
        </p>
        <p class="--paragraph-condensed">
          I’m always excited to chat,
          <a href="mailto:max.jaksa@gmail.com">so feel free to reach out.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionAbout",
  components: {},
};
</script>

<style lang="scss" scoped>
$portrait-size-sm: 200px;
$portrait-size-med: 225px;
$portrait-size-lg: 250px;
.portrait {
  width: $portrait-size-sm;
  height: $portrait-size-sm;
  min-width: $portrait-size-sm;
  min-height: $portrait-size-sm;
  background-image: url("../assets/images/mjaksa-headshot-monacle.png");
  background-size: cover;
  position: relative;
  margin: 0 $padding-sm $padding-sm 0;
  border-radius: 10px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/mjaksa-headshot-monacle.png");
    background-size: cover;
    opacity: 0.3;
    background-blend-mode: screen;
    background-color: $highlight-blue;
    border-radius: 10px;
  }
}

img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.85;
}

@media screen and (min-width: 700px) {
  .portrait {
    width: $portrait-size-med;
    height: $portrait-size-med;
    min-width: $portrait-size-med;
    min-height: $portrait-size-med;
  }
}

@media screen and (min-width: 1100px) {
  .portrait {
    width: $portrait-size-lg;
    height: $portrait-size-lg;
    min-width: $portrait-size-lg;
    min-height: $portrait-size-lg;
  }
}

.__columns {
  display: flex;
  flex-direction: column;
  .__row {
    display: flex;
  }
  .__column {
    display: flex;
    flex-direction: column;
  }
}
</style>
