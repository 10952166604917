<template>
  <div class="mobile-header">
    <img
      class="title-graphic"
      src="../assets/images/max-jaksa-title-horizontal.svg"
      alt="Max Jaksa really likes pizza. This is his website."
    />
    <lottie
      v-bind:animation-data="require('../assets/animations/data.json')"
      v-bind:animation-speed="1.5"
      v-bind:autoplay="true"
      v-bind:loop="true"
      class="pizza-anim"
    />
  </div>
</template>

<script>
import LottieAnim from "../components/LottieAnim.vue";

export default {
  name: "MobileHeader",
  components: {
    lottie: LottieAnim,
  },
  props: {},
};
</script>

<style lang="scss" scoped>
.mobile-header {
  padding-top: $padding-lg;
  display: flex;
  align-items: center;
  justify-content: center;
  .title-graphic {
    width: 75%;
    max-width: 600px;
  }
  .pizza-anim {
    width: 14%;
    max-width: 100px;
  }
}
</style>
