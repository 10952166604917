<template>
  <div class="games">
    <h4>My Games</h4>
    <!-- Turtle Daycare -->
    <h5>Turtle Daycare</h5>
    <p>
      Chill out and take care of some turtles. Make fun stuff out of dirt. It's
      a sandbox game... in a sandbox.
      <a class="--clickable --inline" v-on:click="$emit('nav-to', 'tdc')">
        View Project →
      </a>
    </p>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 30px;
        gap: 25px;
      "
    >
      <img
        class="__single-screen --rounded-corners --clickable"
        src="../assets/images/turtle-daycare/tdc-logo.png"
        alt="Turtle Daycare Logo"
        v-on:click="$emit('nav-to', 'tdc')"
      />
    </div>
    <!-- Orbo's Escape -->
    <h5>Orbo's Escape</h5>
    <p>
      Did you ever play "keep it up in the air" with a balloon? It's like that
      but with a magic disco ball trapped in a space maze.
      <a href="https://apps.apple.com/us/app/orbos-escape/id1519507490">
        Get it for your iPhone→
      </a>
    </p>
    <div class="__3-screens">
      <img
        class="__screen --rounded-corners"
        src="../assets/images/orbos-escape/orbo-screen-1.png"
        alt="Orbo Screenshot 1"
      />
      <img
        class="__screen --rounded-corners"
        src="../assets/images/orbos-escape/orbo-screen-2.png"
        alt="Orbo Screenshot 1"
      />
      <img
        class="__screen --rounded-corners"
        src="../assets/images/orbos-escape/orbo-screen-4.png"
        alt="Orbo Screenshot 1"
      />
    </div>
    <!-- Dish Slayer -->
    <h5>Dish Slayer</h5>
    <p>
      A heavy-metal dish washing simulator, <b>Dish Slayer</b> is a love letter
      to my first "real" job.
      <a href="https://dish-slayer.com/">Play it in your browser right now→ </a>
    </p>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 30px;
        gap: 25px;
      "
    >
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/dish-slayer/dish-slayer-logo.png"
        alt="Dish Slayer Logo"
      />
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/dish-slayer/dish-screen-1.png"
        alt="Dish Slayer Logo"
      />
    </div>
    <!-- Space Rangers Go! -->
    <h5>Space Rangers Go!</h5>
    <p>
      A crack team of Earth's best astronauts are trapped in an abandoned space
      cave and face <i>certain doom</i>. Can you and three of your best friends
      navigate to the exit?
      <a class="--clickable --inline" v-on:click="$emit('nav-to', 'srg')">
        View Project →
      </a>
    </p>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 30px;
        gap: 25px;
      "
    >
      <img
        class="__single-screen --rounded-corners --clickable"
        src="../assets/images/space-rangers-go/srg-logo.png"
        alt="Space Rangers Go Logo"
        v-on:click="$emit('nav-to', 'srg')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionGames",
  components: {},
};
</script>

<style lang="scss" scoped>
</style>
