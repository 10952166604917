<template>
  <div>
    <lottie
      v-bind:options="animationOptions"
      v-on:anim-created="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from "vue-lottie";

export default {
  name: "LottieAnim",
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      anim: null,
    };
  },
  props: {
    animationData: {
      type: Object,
      default() {
        return null;
      },
    },
    animationSpeed: {
      type: Number,
      default: 1,
    },
    animationDirection: {
      type: Number,
      default: 1,
    },
    animationState: {
      type: String,
      default: "",
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    animationOptions() {
      return {
        animationData: this.animationData,
        autoplay: this.autoplay,
        loop: this.loop,
      };
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.setSpeed(this.animationSpeed);
    },
    playOneShot: function (dir) {
      if (dir === 1 || dir === -1) {
        let startFrame = 0;
        let direction = 1;
        if (dir === -1) {
          startFrame = this.anim.totalFrames - 1;
          direction = -1;
        }
        this.anim.goToAndStop(startFrame, true);
        this.anim.setDirection(direction);
        this.anim.play();
      }
    },
    stop: function () {
      this.anim.stop();
    },
    pause: function () {
      this.anim.pause();
    },
  },
  watch: {
    animationState: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue !== "" && newValue !== oldValue) {
          if (newValue === "forward") {
            this.playOneShot(1);
          } else if (newValue === "backward") {
            this.playOneShot(-1);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
// a {
//   color: #42b983;
// }
</style>
