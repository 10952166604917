<template>
  <div>
    <div style="display: flex; flex-direction: column; align-items: center">
      <h3 style="text-align: center">
        Sorry Gus, this page is for friends only.
      </h3>
      <div class="row">
        <div>
          <form
            v-on:submit.prevent="validateBeforeSubmit"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div>
              <input type="password" name="password" v-model.trim="password" />
              <span></span>
            </div>
            <div style="padding: 10px" v-if="error">
              <label>Incorrect password.</label>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storageHelper from "storage-helper";
export default {
  data() {
    return {
      error: null,
      password: null,
    };
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === "hello") {
        this.error = false;
        storageHelper.setItem("user-password", this.password);
        this.$router.push("/");
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>