<template>
  <section-header v-bind:screen-layout="screenLayout" />
  <div class="__divider" />
  <section-games v-on:nav-to="navTo" />
  <div class="__divider" />
  <section-about />
</template>

<script>
import SectionAbout from "../components/SectionAbout.vue";
import SectionGames from "../components/SectionGames.vue";
import SectionHeader from "../components/SectionHeader.vue";

export default {
  name: "Home",
  emits: ["nav-to"],
  components: {
    sectionAbout: SectionAbout,
    sectionHeader: SectionHeader,
    sectionGames: SectionGames,
  },
  props: {
    screenLayout: {
      type: String,
      default: "desktop",
    },
  },
  methods: {
    navTo(section) {
      this.$emit("nav-to", section);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
