<template>
  <div>
    <h2>Hi, I'm Max.</h2>
    <p class="--body-lg">I make games and digital products.</p>
    <!-- Note: I tried v-binding classes based on screenLayout, but ran into some odd safari issues.
    This route seems safest for now. -->
    <div class="skills-block" v-if="screenLayout !== 'phone'">
      <h3 class="--condensed __bullet-after">Art/Creative Direction</h3>
      <h3 class="--condensed __bullet-after">Digital Product Design</h3>
      <h3 class="--condensed __bullet-after">UX/UI Design</h3>
      <h3 class="--condensed __bullet-after">Game Design</h3>
      <h3 class="--condensed __bullet-after">Ghostbusting</h3>
      <h3 class="--condensed">Full Stack Development</h3>
    </div>
    <div class="skills-block --single-column" v-if="screenLayout === 'phone'">
      <h3 class="--condensed __bullet-before">Art/Creative Direction</h3>
      <h3 class="--condensed __bullet-before">Digital Product Design</h3>
      <h3 class="--condensed __bullet-before">UX/UI Design</h3>
      <h3 class="--condensed __bullet-before">Game Design</h3>
      <h3 class="--condensed __bullet-before">Ghostbusting</h3>
      <h3 class="--condensed __bullet-before">Full Stack Development</h3>
    </div>
    <p>
      I’ve worked in-and-around the games industry since 2005. In that time I’ve
      partnered with a variety of clients and organizations — from scrappy tech
      startups to established development houses. I’ve contributed to numerous
      "shipped" games across a variety of platforms, along with several apps and
      websites along the way.
    </p>
    <p>
      Recently I started publishing my own games under the banner
      <i>Lost Triangle Studios</i> where I try out all of my silly,
      less-than-marketable ideas.
    </p>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  components: {},
  props: {
    screenLayout: {
      type: String,
      default: "desktop",
    },
  },
};
</script>

<style lang="scss" scoped>
.skills-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  position: relative;
}

.--single-column {
  flex-direction: column;
}

.__bullet-before {
  margin-left: 40px;
  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -40px;
    margin-top: -10px;
    border-radius: 50%;
    background-color: $highlight-blue;
  }
}

.__bullet-after {
  margin-right: 55px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 17px;
    margin-top: -10px;
    border-radius: 50%;
    background-color: $highlight-blue;
  }
}
</style>
