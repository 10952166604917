
<template>
  <div
    class="__sidebar-and-content"
    v-bind:class="{ '--mobile': screenLayout !== 'desktop' }"
  >
    <div class="__mobile-header-container" v-if="screenLayout !== 'desktop'">
      <mobile-header />
    </div>
    <div class="__side-bar-container" v-if="screenLayout === 'desktop'">
      <side-bar v-on:nav-to="navTo" />
    </div>
    <div class="__content-container">
      <div class="__scrolling-content">
        <router-view v-bind:screen-layout="screenLayout" v-on:nav-to="navTo" />
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeader from "./components/MobileHeader.vue";
import SideBar from "./components/SideBar.vue";

import storageHelper from "storage-helper";
export default {
  name: "App",
  components: {
    mobileHeader: MobileHeader,
    sideBar: SideBar,
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    screenLayout() {
      if (this.screenWidth <= 700) {
        return "phone";
      }
      if (this.screenWidth <= 1100) {
        return "tablet";
      }
      return "desktop";
    },
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    navTo(section) {
      this.$router.push(section);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    storageHelper.setItem("user-password", "");
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 500px;

#app {
  position: absolute;
  height: 100%;
  width: 100%;
}

.__sidebar-and-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  .__mobile-header-container {
    position: relative;
    width: 100%;
  }
  .__side-bar-container {
    position: relative;
    width: 30%;
    max-width: $sidebar-width;
  }
  .__content-container {
    width: 60%;
    max-width: 800px;
    .__scrolling-content {
      padding: $padding-lg;
    }
  }
}

.--mobile {
  flex-direction: column;
  align-items: center;
  .__content-container {
    max-width: none;
    width: 100%;
    .__scrolling-content {
      padding: $padding-sm;
    }
  }
}

@media screen and (min-width: 1550px) {
  .__sidebar-and-content {
    .__content-container {
      max-width: 1000px;
    }
  }
}
</style>
